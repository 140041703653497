<template>
  <div v-if="category !== null">
    <b-card>
      <h1 class="mb-3">ویرایش دسته</h1>
      <b-form>
        <b-row>
          <!-- Field: name -->
          <b-col cols="12" md="6">
            <b-form-group label="نام دسته" label-for="name">
              <b-form-input id="name" v-model="category.name" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <language-picker
              @getLanguage="setLanguage"
              :defaultLanguageId="category.languageId"
            ></language-picker>
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          class="mt-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="updateCategory"
        >
          ذخیره تغییرات
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import LanguagePicker from "../../../components/utils/LanguagePicker.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BlogCategoryUpdateRequest,
  BlogCategoryGetRequest,
} from "@/libs/Api/Blogex";

export default {
  name: "categories-edit",
  title: "ویرایش دسته",
  data() {
    return {
      category: null,
      categoryProperties: null,
      categoryId: this.$route.params.id,
      addNewCategoryProperty: false,
      myTableColumns: [
        { key: "categoryPropertyId", label: "شناسه" },
        { key: "name", label: "نام" },
        { key: "delete", label: "حذف" },
        { key: "edit", label: "ویرایش" },
      ],
      defaultCreateCategoryPropertyData: {
        isDeleted: false,
        categoryPropertyId: 0,
        categoryId: this.$route.params.id,
        name: "",
      },
      updateCategoryPropertyData: null,
    };
  },
  async created() {
    await this.getCategory();
  },
  methods: {
    setLanguage(language) {
      this.category.languageId = language.languageId;
    },
    async getCategory() {
      let _this = this;

      let blogCategoryGetRequest = new BlogCategoryGetRequest(_this);
      blogCategoryGetRequest.setId(_this.categoryId);
      await blogCategoryGetRequest.fetch(
        function (response) {
          if (response.isSuccess) {
            _this.category = response.data;
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
    async updateCategory() {
      let _this = this;

      let blogCategoryUpdateRequest = new BlogCategoryUpdateRequest(_this);
      blogCategoryUpdateRequest.setParams(_this.category);
      await blogCategoryUpdateRequest.fetch(
        function (response) {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `تغییرات اعمال شد.`,
              },
            });
          }
          _this.getCategory();
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    LanguagePicker,
    vSelect,
  },
};
</script>

<style scoped></style>
